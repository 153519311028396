export const languagesSpokenList = [
  "Abkhazian",
  "Afar",
  "Afrikaans",
  "Akan",
  "Albanian",
  "Amharic",
  "Arabic",
  "Aragonese",
  "Armenian",
  "Assamese",
  "Avaric",
  "Avestan",
  "Aymara",
  "Azerbaijani",
  "Bambara",
  "Bashkir",
  "Basque",
  "Bawean, Baweanese",
  "Belarusian",
  "Bengali",
  "Bihari languages",
  "Bislama",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Burmese",
  "Catalan, Valencian",
  "Cantonese",
  "Central Khmer",
  "Chamorro",
  "Chechen",
  "Chichewa, Chewa, Nyanja",
  "Chinese (Mandarin)",
  "Church Slavonic, Old Bulgarian",
  "Chuvash",
  "Cornish",
  "Corsican",
  "Cree",
  "Croatian",
  "Czech",
  "Danish",
  "Divehi, Dhivehi, Maldivian",
  "Dutch, Flemish",
  "Dzongkha",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Faroese",
  "Fijian",
  "Finnish",
  "French",
  "Fulah",
  "Gaelic, Scottish Gaelic",
  "Galician",
  "Ganda",
  "Georgian",
  "German",
  "Gikuyu, Kikuyu",
  "Greek (Modern)",
  "Greenlandic, Kalaallisut",
  "Guarani",
  "Gujarati",
  "Haitian, Haitian Creole",
  "Hainanese",
  "Hakka",
  "Hausa",
  "Hebrew",
  "Henghua, Puxian Min",
  "Herero",
  "Hindi",
  "Hiri Motu",
  "Hokchew, Fuzhou dialect",
  "Hokkien",
  "Hungarian",
  "Icelandic",
  "Ido",
  "Igbo",
  "Indonesian",
  "Interlingua",
  "Interlingue",
  "Inuktitut",
  "Inupiaq",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kanuri",
  "Kashmiri",
  "Kazakh",
  "Kinyarwanda",
  "Komi",
  "Kongo",
  "Korean",
  "Kwanyama, Kuanyama",
  "Kurdish",
  "Kyrgyz",
  "Lao",
  "Latin",
  "Latvian",
  "Letzeburgesch, Luxembourgish",
  "Limburgish, Limburgan, Limburger",
  "Lingala",
  "Lithuanian",
  "Luba-Katanga",
  "Macedonian",
  "Madurese",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Manx",
  "Maori",
  "Marathi",
  "Marshallese",
  "Minangkabau",
  "Moldovan, Moldavian, Romanian",
  "Mongolian",
  "Nauru",
  "Navajo, Navaho",
  "Northern Ndebele",
  "Ndonga",
  "Nepali",
  "Northern Sami",
  "Norwegian",
  "Norwegian Bokmål",
  "Norwegian Nynorsk",
  "Nuosu, Sichuan Yi",
  "Occitan (post 1500)",
  "Ojibwa",
  "Oriya",
  "Oromo",
  "Ossetian, Ossetic",
  "Pali",
  "Panjabi, Punjabi",
  "Pashto, Pushto",
  "Persian",
  "Polish",
  "Portuguese",
  "Quechua",
  "Romansh",
  "Rundi",
  "Russian",
  "Samoan",
  "Sango",
  "Sanskrit",
  "Sardinian",
  "Serbian",
  "Shanghainese", 
  "Shona",
  "Sindhi",
  "Sinhala, Sinhalese",
  "Slovak",
  "Slovenian",
  "Somali",
  "Sotho, Southern",
  "South Ndebele",
  "Spanish, Castilian",
  "Sundanese",
  "Swahili",
  "Swati",
  "Swedish",
  "Tagalog",
  "Tahitian",
  "Tajik",
  "Tamil",
  "Tatar",
  "Telugu",
  "Teochew",
  "Thai",
  "Tibetan",
  "Tigrinya",
  "Tonga (Tonga Islands)",
  "Tsonga",
  "Tswana",
  "Turkish",
  "Turkmen",
  "Twi",
  "Uighur, Uyghur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Venda",
  "Vietnamese",
  "Volap_k",
  "Walloon",
  "Welsh",
  "Western Frisian",
  "Wolof",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zhuang, Chuang",
  "Zulu",
];
